import React from 'react';
import Head from 'next/head';
import { SWRConfig, cache } from 'swr';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'placeholder-loading/dist/css/placeholder-loading.min.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Provider from 'contexts';
import { fetcher } from 'utils/axios';

config.autoAddCss = false;

const MyApp = ({ Component, pageProps }) => (
	<>
		<Head>
			<link href="https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,400&display=swap" rel="stylesheet" />
		</Head>
		<Provider>
			<SWRConfig value={{
				fetcher,
				onError: (e) => {
					switch (e?.response?.status) {
					case 401:
					case 404:
						cache.clear();
						break;
					default:
					}
				},
			}}
			>
				<Component {...pageProps} />
			</SWRConfig>
		</Provider>
		<style global jsx>
			{`
			@font-face {
				font-family: EUDC;
				src: url('/fonts/nukword1110504.woff2');
				src: url('/fonts/nukword1110504.woff2') format('woff2');
				src: url('/fonts/nukword1110504.woff');
				src: url('/fonts/nukword1110504.woff') format('woff');
			}
			body {
				font-family: 'Noto Sans TC', sans-serif, EUDC;
			}
		`}
		</style>
	</>
);

export default MyApp;
