import axios from 'axios';

const instance = axios.create({
	baseURL: `${process.env.NEXT_PUBLIC_API_PATH}`,
	withCredentials: true,
});

instance.interceptors
	.response.use((response) => response, (error) => Promise.reject(error));

// const fetcher = (...args) => fetch(...args).then(res => res.ok && res.json());

const fetcher = (...args) => instance.get(...args)
	.then(res => res.data)
	.catch(e => { throw e; });


export default instance;
export { fetcher };
