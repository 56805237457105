import React, { createContext, useReducer } from 'react';
import axios from 'utils/axios';
import { console } from 'globalthis/implementation';

const ContextStore = createContext({
	user: {},
});

const userInitState = { user: globalThis.localStorage && localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {} };

const userReducer = (state, action) => {
	switch (action.type) {
	case 'LOGIN':
		if (globalThis.localStorage) {
			localStorage.setItem('user', JSON.stringify(action.payload));
		}
		return {
			...state,
			user: {
				...state.user,
				...action.payload,
			},
		};
	case 'LOGOUT':
		if (globalThis.localStorage) {
			localStorage.removeItem('user');
		}
		axios.get('/api/v1/user/logout');
		return {
			...state,
			user: {},
		};
	case 'AGREETOS': {
		const s = {
			...state,
			user: {
				...state.user,
				...action.payload,
			},
		};
		console.log(s);
		if (globalThis.localStorage) {
			localStorage.setItem('user', JSON.stringify(s.user));
		}
		return s;
	}
	default:
		return state;
	}
};

const Provider = (props) => {
	const { children } = props;
	const [state, uDispatch] = useReducer(userReducer, userInitState);
	return (
		<ContextStore.Provider value={{
			state,
			uDispatch,
		}}
		>
			{ children }
		</ContextStore.Provider>
	);
};

export default Provider;
export {
	ContextStore,
};
